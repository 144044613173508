/** Libs */
import MailchimpSubscribe from "react-mailchimp-subscribe";
/** Global Components */
import { Button } from "components/anti";

/** Local Components */

/** Assets */
import subsBadge from 'assets/img/grfx/subs.svg';
import { useEffect, useState } from "react";
import { fetchAPINext, submitSubscription } from "lib/api/api";
import { IconChevronBottom } from "components/icons";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { countries } from "country";
import { useScrollAnim } from "components/hooks/hooks";
import { AnimatePresence, motion } from "framer-motion";
import newsletterBadge from "assets/img/grfx/newsletter-icon.png"
/** Utils */


const RenderComponent = ({ item, exclusive, status = "", message = "", subscribe = () => null,cb }) => {
  useEffect(() => {
    if (status === "success") {
      toast.success("Thank you! Your email has been successfully submitted")
      cb()
    }
    if (status === "error") {
      toast.error(message)
    }
  }, [status])
  return (
    <div className={`sc-subscription-content row m-0 bg-${item?.bgColor}`}>
      {item?.badge}
      <div className={`col-12 col-md-6 px-0 mb-md-0 ${!exclusive && 'mb-3'}`}>
        <h3 className="mb-3 font-semibold text-30-36" dangerouslySetInnerHTML={{ __html: item.title }} />
        {item?.subtitle}
      </div>
      <div className="col-12 col-md-6 px-0 d-flex align-items-md-center">
        <form 
        onSubmit={(e) => {
          item?.onSubmit(e)
          subscribe()
        }} className="align-items-md-end m-0 w-lg-100 row">
          <div className="input-phonenumber mb-3 mb-md-0 col-lg-7 col-12 px-0">
            <label className="text-12-16 text-cak-white-70">{item?.label}</label>
            {item?.inputEL}
          </div>

          <div className="col-lg-3 col-12 ml-lg-4 px-0 subscription-btn-submit">
            <Button
              type='submit'
              className={`text-14 w-100 w-lg-auto font-semibold bold-border loading-${item?.loadingState}`}
            >
              {item?.loadingState === 'finish' ? 'SUBMITTED' : ' SUBMIT '}
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default function Subscription({ className, containerClassName, noGridBG, exclusive, callForSuccess = () => null, types = ["subscription", "newsletter"] }) {
  // State  
  const [loadingState, loadingStateSet] = useState('init');
  const [loadingStateNewsletter, loadingStateNewsletterSet] = useState('init');
  const [selectedCode, selectedCodeSet] = useState();
  const [isUserRegistered, isUserRegisteredSet] = useState(false);
  const [code, codeSet] = useState(countries.find(i => i?.name === 'Indonesia'));
  const [hideTemp, hideTempSet] = useState(false);

  // Hooks
  const { register, handleSubmit, formState: { errors, touchedFields }, getValues, reset, setValue, watch } = useForm({ mode: 'onChange', defaultValues: { code: countries.find(i => i?.name === 'Indonesia'), phoneNumber: '' } });
  const { register: registerNewsletter, handleSubmit: handleSubmitnewsletter, formState: { errors: errorsNewsletter, touchedFields: touchedFieldsNewsletter }, getValues: getValuesNewsletter, reset: resetNewsletter, setValue: setValueNewsletter, watch: watchNewsletter } = useForm({ mode: 'onChange' });
  // const [submitSubscription, { data, loading, error }] = useMutation(M_SUBSCRIPTION);
  const [ref, anim] = useScrollAnim();
  // Func
  const onSubmitSubscription = async () => {
    loadingStateSet('start');

    const phoneNumber = getValues('phoneNumber').slice(0, 1) === '0' ? `${selectedCode?.dial_code}${getValues('phoneNumber').slice(1, getValues('phoneNumber').length)}` : `${selectedCode?.dial_code}${getValues('phoneNumber')}`;

    const res = await fetchAPINext('submit_subscription', { phoneNumber });

    if (res?.data?.submit2FormJoinUs?.status === 'success') {
      localStorage.setItem("pn", phoneNumber);
      reset();
      loadingStateSet('finish');
      setTimeout(() => loadingStateSet('init'), 1500);
      toast.success('Successfully joined!');
      if (exclusive) {
        hideTempSet(true);
        callForSuccess();
      }
      // window.location.reload()
    } else {
      loadingStateSet('init');
      toast.error(res?.data?.submit2FormJoinUs?.message);
    }
  }

  const onSubmitNewsletter = async (val, subscribe) => {    
    loadingStateNewsletterSet("start")
    // console.log(subscribe)
    // subscribe(formData)
  }

  // Use Effect
  useEffect(() => {
    selectedCodeSet(watch('code'))
  }, [watch])
  // logs
  // console.log(selectedCode)

  useEffect(() => {
    const RegisteredNumber = localStorage.getItem('pn');
    if (RegisteredNumber) {
      isUserRegisteredSet(true);
    }
  }, [])

  // if (isUserRegistered) return null;

  useEffect(() => {
    if (typeof window !== undefined && exclusive) {
      const getEl = document.querySelector('.sc-subscription');
      const bodyPosition = document.body.getBoundingClientRect().top;
      const bodyHeight = document.body.getBoundingClientRect().height;
      const elPosition = getEl.getBoundingClientRect().y;
      const detectScroll = () => {
        // console.log((window.scrollY / 25) - (window.scrollY / 100) - 20)
        if (window.scrollY >= (elPosition - bodyPosition - 200)) {
          getEl.style.transform = `translateY(${-(window.scrollY / 25) - (window.scrollY / 100) + 10}%)`;
        } else {
          getEl.style.transform = `translateY(-17%)`;
        }
      }
      window.addEventListener('scroll', detectScroll)

      return () => window.removeEventListener('scroll', detectScroll)
    }

  }, [])

  const mode = (value) => {
    switch (value) {
      case "subscription":
        return {
          bgColor: "cak-blue",
          badge: !exclusive && (
            <div className="sc-subscription-badge">
              <img src={subsBadge} />
              <h3 className="position-relative text-black text-12 font-normal text-center m-0">EXCLUSIVE BENEFITS</h3>
            </div>
          ),
          title: !exclusive ? `Free Unlimited Access <br className="d-none d-md-block" />to Exclusive Analysis` : `Join Our Exclusive Group to Read the Full Article for Free`,
          subtitle: !exclusive && <p className="m-0 text-16">Unlock more benefits by joining our private Whatsapp group</p>,
          label: "MOBILE NUMBER",
          loadingState: loadingState,
          inputEL: (
            <>
              <div className="d-flex input-phonenumber-field">
                <div className="pointer d-flex input-phonenumber-field-prefix" onClick={() => document.querySelector('.dm-code').classList.toggle('d-flex')}>
                  <div className="circle-flag">
                    <img src={`https://flagsapi.com/${code?.code}/flat/64.png`} className='img-full' />
                  </div>
                  <span className="mx-1 text-black">{code?.dial_code}</span>
                  <IconChevronBottom fill='#080808' />
                </div>
                <div className="dropdown-menu dm-code flex-column" style={{ height: 200, overflow: 'scroll' }}>
                  {/* CODE ITEM */}
                  {countries.map(i => (
                    <div
                      key={i?.code}
                      className="dropdown-item pointer d-flex"
                      onClick={() => {
                        setValue('code', i);
                        codeSet(i);
                        document.querySelector('.dm-code').classList.remove('d-flex')
                      }}>
                      <div className="circle-flag mr-2">
                        <img src={`https://flagsapi.com/${i?.code}/flat/64.png`} className='img-full' />
                      </div>
                      <span className="mx-1 text-black">{i?.code}</span>
                    </div>
                  ))
                  }
                </div>
                <div className="barrier" />
                <input
                  placeholder="Enter mobile number"
                  className={(touchedFields?.phoneNumber && errors?.phoneNumber) && 'invalid-input'}
                  {...register("phoneNumber", {
                    required: true, minLength: { value: 6, message: 'Must be at least 6 character' },
                    maxLength: { value: 13, message: 'Must be max 13 character' },
                    pattern: {
                      value: /^\d+$/,
                      message: 'Invalid phone number'
                    }
                  })}
                />
              </div>
              {(touchedFields?.phoneNumber && errors?.phoneNumber) &&
                <>
                  <span className="text-cak-red w-100 justify-content-end d-flex d-lg-none">{errors?.phoneNumber?.message}</span>
                  <span className="text-cak-red w-100 justify-content-end d-none d-lg-flex position-absolute">{errors?.phoneNumber?.message}</span>
                </>
              }
            </>
          ),
          onSubmit: handleSubmit(onSubmitSubscription)
        }

      case "newsletter":
        return {
          bgColor: "cak-blue-3",
          badge: (
            <div className="sc-subscription-badge in-newsletter">
              <img src={newsletterBadge} />
            </div>
          ),
          title: `Newsletter Subscription`,
          subtitle: `Get weekly curated highlights archived directly to your email`,
          label: "EMAIL ADDRESS",
          loadingState: loadingStateNewsletter,
          inputEL: (
            <>
              <div className="d-flex input-phonenumber-field">
                <input
                  style={{ height: 48 }}
                  placeholder="Enter email address"
                  className={(touchedFieldsNewsletter?.email && errorsNewsletter?.email) && 'invalid-input'}
                  {...registerNewsletter("email", {
                    required: true,
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: 'Invalid email'
                    }
                  })}
                />
              </div>
              {(touchedFieldsNewsletter?.email && errorsNewsletter?.email) &&
                <>
                  <span className="text-cak-red w-100 justify-content-end d-flex d-lg-none">{errorsNewsletter?.email?.message}</span>
                  <span className="text-cak-red w-100 justify-content-end d-none d-lg-flex position-absolute">{errorsNewsletter?.email?.message}</span>
                </>
              }
            </>
          ),
          onSubmit:handleSubmitnewsletter((val,_,subscribe) => onSubmitNewsletter(val,subscribe)),          
        }

      default:
        break;
    }
  }

  return (
    <AnimatePresence>
      <motion.div exit={{ opacity: 0 }} ref={ref} className={`sc-subscription ${className} ${noGridBG && 'no-grid-bg'}`}>
        <div style={{ gap: 24 }} className={`container mw-xxl mx-auto my-5 py-5 d-flex flex-column align-items-center ${containerClassName} ${anim(1)}`}>
          {types.map((item, i) => !hideTemp && (
            item !== "newsletter" ?
              <RenderComponent key={i} item={mode(item)} exclusive={exclusive} />
              :
              <MailchimpSubscribe
                url="https://cakinvestmentclub.us10.list-manage.com/subscribe/post?u=c0c97a727d52986aa121fe867&id=65e69c21b2&f_id=00e6dbe5f0"
                render={({ message, status, subscribe }) =>
                  <RenderComponent
                    key={i}
                    item={mode(item)}
                    exclusive={exclusive}                    
                    status={status}
                    message={message}
                    cb={() => {
                      loadingStateNewsletterSet("finish");
                      setTimeout(() => loadingStateNewsletterSet('init'), 1500);
                      setValueNewsletter("email","")
                    }}  
                    subscribe={() => {
                      if(!errorsNewsletter["email"]) {
                        const formData = {
                          EMAIL: getValuesNewsletter("email"),
                          NAME: getValuesNewsletter("email").split("@")[0]
                        }                                            
                        subscribe(formData)
                      }
                    }}                  
                  />
                } // message: message from error, status: status for subscribing, subscribe: subscribe function for subscribe
              />
          ))}
        </div>
      </motion.div>
    </AnimatePresence>
  )
}