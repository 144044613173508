export const countries = [  
  {
    name: "Brunei Darussalam",
    dial_code: "+673",
    code: "BN",
  },  
  {
    name: "Cambodia",
    dial_code: "+855",
    code: "KH",
  },  
  {
    name: "Indonesia",
    dial_code: "+62",
    code: "ID",
  },  
  {
    name: "Laos",
    dial_code: "+856",
    code: "LA",
  },  
  {
    name: "Malaysia",
    dial_code: "+60",
    code: "MY",
  },  
  {
    name: "Myanmar",
    dial_code: "+95",
    code: "MM",
  },  
  {
    name: "Philippines",
    dial_code: "+63",
    code: "PH",
  },  
  {
    name: "Singapore",
    dial_code: "+65",
    code: "SG",
  },  
  {
    name: "Thailand",
    dial_code: "+66",
    code: "TH",
  },
  {
    name: "Timor-Leste",
    dial_code: "+670",
    code: "TL",
  },  
  {
    name: "United States",
    dial_code: "+1",
    code: "US",
  },  
  {
    name: "Vietnam",
    dial_code: "+84",
    code: "VN",
  },  
]
